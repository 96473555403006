"use client";
import Image from "next/image";

const ServicesSection = () => {
  return (
    <section className="bg-cover bg-center py-16">
      <div className="max-w-screen-xl mx-auto text-center">
        <h2 className="text-3xl md:text-4xl font-extrabold uppercase mb-10">
          What is Included in Our Services
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-white shadow-2xl rounded-lg overflow-hidden transform transition duration-500 hover:scale-105 hover:shadow-2xl"
            >
              <div className="relative overflow-hidden">
                <Image
                  src={service.image}
                  alt={service.title}
                  width={500}
                  height={250}
                  className="w-full h-72 object-cover transition duration-500 hover:scale-110"
                />
              </div>
              <div className="p-6">
                <h5 className="text-2xl font-bold text-gray-800 mb-6">
                  {service.title}
                </h5>
                <ul className="text-gray-600 text-left space-y-2">
                  {service.details.map((detail, i) => (
                    <li key={i} className="flex items-start text-lg">
                      <span className="text-orange-500 font-bold mr-2">•</span>
                      {detail}
                    </li>
                  ))}
                </ul>
                <div className="mt-4 text-center">
                  <button className="bg-orange-500 text-white px-5 py-2 rounded-full font-medium transition duration-300 hover:bg-orange-600 hover:shadow-lg">
                    Read More
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const services = [
  {
    title: "Vedic Astrological Horoscopes",
    image: "/images/vedic-astrological-oroscopes.jpg",
    details: [
      "Detailed analysis of your birth chart based on Vedic astrology.",
      "Insights into career, relationships, health, and spiritual growth.",
      "Personalized guidance on key life events, challenges, and opportunities.",
    ],
  },
  {
    title: "Marriage Compatibility",
    image: "/images/marriage.jpg",
    details: [
      "Comprehensive comparison of two horoscopes for compatibility.",
      "Assessment of relationship dynamics and long-term harmony.",
      "Focus on birthdate compatibility and Nakshatra-based analysis.",
    ],
  },
  {
    title: "Astrology Consultation",
    image: "/images/consultation.jpg",
    details: [
      "Personalized sessions addressing career, finances, and health concerns.",
      "Actionable advice rooted in astrological wisdom.",
      "Empowering guidance to help you make informed life decisions.",
    ],
  },
];

export default ServicesSection;
