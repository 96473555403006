import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/shriraamastrologer.com/next.js/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/shriraamastrologer.com/next.js/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/shriraamastrologer.com/next.js/src/app/Consultation.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/shriraamastrologer.com/next.js/src/app/Guidesec.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/shriraamastrologer.com/next.js/src/app/Intro.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/shriraamastrologer.com/next.js/src/app/Moonsign.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/shriraamastrologer.com/next.js/src/app/Servicesec.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/shriraamastrologer.com/next.js/src/app/Whatsinclude.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/shriraamastrologer.com/next.js/src/app/Whychoose.jsx");
