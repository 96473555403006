"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { FaCheckCircle } from "react-icons/fa";

const WhyChoose = () => {
  return (
    <div className="relative">
      <div className="absolute inset-0">
        <Image
          src="/images/weekly-horoscope-prediction.jpg"
          alt="Background Image"
          layout="fill"
          objectFit="cover"
          className="opacity-50 bg-blue-950"
        />
      </div>
      <section className="relative bg-black opacity-85 py-12 md:py-24 lg:py-28">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="text-center mb-10">
            <h2 className="text-3xl md:text-4xl font-extrabold text-yellow-400 uppercase">
              Why Choose Us
            </h2>
          </div>
          <div className=" gap-8 items-center text-white">
            <div className="grid grid-cols-1 lg:grid-cols-3">
              {features.map((feature, index) => (
                <div key={index} className="mb-8 flex items-start">
                  <FaCheckCircle className="text-yellow-400 text-2xl flex-shrink-0 mr-3" />
                  <div>
                    <h5 className="text-xl font-bold text-yellow-400 mb-2">
                      {feature.title}
                    </h5>
                    <p className="text-lg md:text-xl font-medium">
                      {feature.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const features = [
  {
    title: "Personalized Readings",
    description:
      "Our services are tailored to your unique life circumstances, offering personalized and relevant guidance.",
  },
  {
    title: "Blend of Tradition and Modern Insights",
    description:
      "We combine traditional Vedic wisdom with modern perspectives for actionable advice in today’s world.",
  },
  {
    title: "Proven Success",
    description:
      "Thousands of satisfied clients worldwide have benefited from our life-changing astrological insights.",
  },
  {
    title: "Comprehensive Solutions",
    description:
      "We guide on relationships, career, health, personal growth, and more, providing clarity.",
  },
  {
    title: "Effective Remedies",
    description:
      "We suggest home-made remedies for all planetary influences to bring positivity and success to your life.",
  },
];

export default WhyChoose;
